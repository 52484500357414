@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* @import url('https://fonts.googleapis.com/css2?family=Bricolage+Grotesque:opsz,wght@12..96,200&family=Instrument+Sans:wght@400;500;600;700&family=Red+Hat+Display:wght@300;400;500;600;700;800;900&display=swap'); */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  /* font-family: 'Bricolage Grotesque'; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html * {
  font-size: 16px;
  /* font-family: Montserrat, sans-serif; */
}

.centerPage {
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
}

.register-modal::-webkit-scrollbar {
  display: none;
}

.register-modal {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#yellowHero {
  background-image: url('./assets/home-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

#yellowFAQ {
  background-image: url('./assets/faq-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.yellowTestimonials .owl-dots {
  position: absolute;
  top: -60px;
}

.yellowTestimonials .owl-dot {
  width: 30px;
  height: 5px;
  background-color: #1f1f1f!important;
  opacity: 0.2;
  margin-right: 5px;
}

.yellowTestimonials .owl-dots .active {
  background-color: #1f1f1f;
  opacity: 1;
}