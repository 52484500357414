.bg-F5F5DC {
    background-color: #F5F5DC;
}

.text-6941C6 {
    color: #6941C6;
}
.bg-7F56D9 {
    background-color: #7F56D9;
}

.BricolageFont {
    font-family: 'Bricolage Grotesque';
}

.widthWeb {
    margin: 25px;
    width: -webkit-fill-available;
}
/* .centerPage {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
} */