.featureCard {
    display: flex;
    /* padding: 32px; */
    flex-direction: column;
    /* justify-content: center; */
    align-items: flex-start;
    gap: 12px;
    align-self: stretch;
    border: 1px solid #F2F2F2;
    border-radius: 12px;
    background: #FFF;
}

.timeCard {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* gap: 12px; */
    /* flex: 1 0 0; */
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.1);
}
.BricolageFont {
    font-family: 'Bricolage Grotesque';
}