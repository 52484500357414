body {
  font-family: 'Space Grotesk' !important;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

.purple-bg {
  background: #9831ff !important;
}

.purple-btn {
  padding: 10px;
  background-image: url(../../assets/purple/purple-btn.png) !important;
  background-repeat: no-repeat;
  /* background-position: center; */
  border-radius: 30px;
  /* background-size: cover !important; */
  background-color: unset !important;
  /* border: unset !important; */
}

.purple-text {
  color: #c892ff !important;
}

.max-width {
  max-width: 1240px;
}

.margin-top-32 {
  margin-top: 32px;
}

.margin-top-64 {
  margin-top: 64px;
}

.margin-top-40 {
  margin-top: 32px;
}

.nav-bar-div {
  display: flex;
  padding-top: 24px;
  padding-bottom: 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.home-nav {
  /* display: flex; */
  /* max-width: 1040px; */
  margin: auto;
  padding: 12px 16px;
  align-items: center;
  padding-inline: 0px;

  background: transparent;
  backdrop-filter: blur(20px);
  background-image: url(../../assets/Blue/bgBlue.svg) !important;
  z-index: 100;
}

.home-nav .nav-inline {
  display: flex;
  /* max-width: 1040px; */
  margin: auto;
  padding: 12px 16px;
  align-items: center;
  padding-inline: 0px;
  width: 100%;
}

.home-nav h2 {
  color: #f2f2f2;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home-nav .logo {
  width: 70px;
  height: 19px;
  /* margin: auto; */
  margin-left: initial;
}

.home-nav .countdowns {
  margin: auto;
  text-align: center;
  vertical-align: middle;
  align-items: baseline;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.home-nav .dots {
  width: auto;
  height: auto;
}

.home-nav .countdowns h5,
.home-nav .countdowns h6 {
  color: #fff;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.home-nav .countdowns h6 {
  opacity: 0.4;
}

.home-btn .book-btn {
  border-radius: 50px;
  /* border: 2px solid rgba(255, 255, 255, 0.24); */
  /* background: #9831FF; */

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  display: flex;
  padding: 12px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 155.556% */
}

.home-nav .register-btn {
  display: none;
}

.home-nav .countdowns {
}

.home {
  background-image: url(../../assets/Blue/bgBlue.svg);
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  background-size: 100%;
  background-repeat: no-repeat;
}

.home .max-width .mx-auto {
  margin-bottom: 80px;
}

.home .head-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  width: 700px;
  margin: auto;
}

.home .head-div h5 {
  color: #f2f2f2;
  text-align: center;
  font-family: 'Instrument Sans' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 28px */
  text-transform: uppercase;
}

.home .head-div h1 {
  color: #f2f2f2;
  text-align: center;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 76.8px */
}

.home .head-div h6 {
  opacity: 0.6;

  color: #fff;
  text-align: center;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 39.6px */
}

.vl {
  border-left: 2px solid white;
  opacity: 0.2;
  height: 55px;
}

.home .rating-div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.home .rate-div h4 {
  display: flex;
  align-items: center;
  gap: 8px;

  color: #f2f2f2;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.home progress::-moz-progress-bar {
  background: linear-gradient(90deg, #9428ff 0%, #e0c0ff 100%);
}
.home progress::-webkit-progress-value {
  background: linear-gradient(90deg, #9428ff 0%, #e0c0ff 100%);
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.home progress {
  background: linear-gradient(90deg, #9428ff 0%, #e0c0ff 100%);
  width: -webkit-fill-available;
  margin-top: 56px;
}

.home progress[value]::-webkit-progress-bar {
  background: #262626;
}
.home progress[value] {
  -webkit-appearance: none;
}

.home .progress-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}
.home .progress-div p {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 120% */
}
.home .progress-div p span {
  color: rgba(255, 255, 255, 0.6);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
}

.home .brand-carousel {
  overflow-x: hidden;
  margin-left: auto;

  background: transparent;
  display: flex;
  width: -webkit-fill-available;
  padding: 16px 24px;
  align-items: center;
  gap: 24px;
  z-index: 1;
  position: absolute;
  bottom: 35%;
}

.home .brand-carousel1 {
  bottom: 30%;
}

.home .brand-carousel h4 {
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  text-transform: uppercase;
  /* width: 480px; */
  margin-left: auto;
  font-family: 'Bricolage Grotesque' !important;
}

@keyframes infiniteScroll {
  from {
    transform: translateX(-30%);
  }
  to {
    transform: translateX(0%);
  }
}

.home .brand-carousel .horizontal-scrolling-items {
  display: flex;
  animation-name: infiniteScroll;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  text-transform: uppercase;

  width: -webkit-fill-available;
  margin-left: auto;
  height: fit-content;
  gap: 24px;
}

@keyframes infinitely {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-30%);
  }
}
.home .brand-carousel .horizontal-scrolling-items2 {
  display: flex;
  animation-name: infinitely;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 22.4px */
  text-transform: uppercase;

  width: -webkit-fill-available;
  margin-left: auto;
  height: fit-content;
  gap: 24px;
}
.home .brand-carousel .horizontal-scrolling-items__item {
  white-space: nowrap;
  height: 24px;
}

.home .brand-carousel .vr-line {
  margin-left: 24px;
  width: 2px;
  height: 24px;
}

.home .creative-div {
  /* background-image: url(../../assets/purple/creator-img.png); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 600px;
  height: 600px;
  margin: auto;
  z-index: 10;
  position: relative;
  margin-top: 80px;
  position: relative;
  border-radius: 20px;
}
.home .creative-div .img-div {
  display: flex;
  padding: 360px 32px 32px 32px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  background: linear-gradient(180deg, rgba(31, 31, 31, 0) 0%, #1f1f1f 100%);
  position: absolute;
  bottom: 0;
  width: -webkit-fill-available;
  border-radius: 20px;
}

.home .creative-div .img-div .img-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
}

.home .creative-div .img-div .img-text h2 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.home .creative-div .img-div .img-text h6 {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home .creative-div .img-div .img-foot {
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  width: -webkit-fill-available;
}
.home .creative-div .img-div .img-foot p {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.12);
  backdrop-filter: blur(16px);
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  width: 100%;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

/* Learn */
.learn {
  padding-top: 140px;
  padding-bottom: 140px;

  background-image: url(../../assets/purple/learn-bg.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.learn h1 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 160%; /* 64px */
  max-width: 732px;
  border-bottom: none !important;
  backdrop-filter: none !important;
  padding: 0 !important;
  margin-bottom: 48px;
}
.learn h2 {
  color: #fff;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.learn h6 {
  color: #a4a4a4;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
  margin-top: 8px;
}

.learn .pills {
  display: flex;
  align-items: center;
  align-content: center;
  gap: 24px;
  align-self: stretch;
  flex-wrap: wrap;

  margin-top: 100px;
}

.learn .pills p {
  border-radius: 28px;
  background: #3a3a3a;
  box-shadow: 0px 12px 32px 0px rgba(31, 31, 31, 0.04);
  display: flex;
  padding: 16px 24px;
  align-items: center;
  gap: 12px;

  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.workshop {
  padding-top: 120px;
  padding-bottom: 120px;
  background-color: #1f1f1f;
}

.workshop h5 {
  color: #c892ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  text-transform: uppercase;
}
.workshop h1 {
  color: #fff;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 50.4px */

  margin-top: 8px;
  margin-bottom: 24px;
  padding: 0 !important;
  backdrop-filter: none !important;
  border: none !important;
}

.workshop .text-div {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.workshop .text-div img {
  width: 12px;
  height: 12px;
}
.workshop .text-div p {
  color: #b8b8b8;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 160%; /* 28.8px */
}

.workshop .get-btn {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%; /* 140% */

  border-radius: 400px;
  border: 2px solid rgba(255, 255, 255, 0.24);
  background: #9831ff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;

  margin-top: 40px;
}
.workshop .get-btn span {
  color: rgba(255, 255, 255, 0.6);
  text-decoration-line: line-through;
}

.workshop .cards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  border-radius: 16px;
  background: linear-gradient(180deg, #d9b2ff 0%, #af60ff 100%), #464646;
  overflow-y: hidden;
  box-shadow: none !important;
  margin-left: auto;
}

.testimonials .cards-div {
  margin: auto;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  gap: 32px;

  height: auto;
  /* max-height: 567px; */

  /* position: relative;
    animation-name: example;
    animation-duration: 15s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-direction: alternate; */
}

@keyframes example {
  from {
    top: 30px;
  }
  to {
    top: -530px;
  }
}
.testimonials .card-div {
  border-radius: 16px;
  background: #fff;
  box-shadow: none !important;

  display: flex;
  /* width: 440px; */
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}
.testimonials .card-div h3 {
  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Instrument Sans' !important;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px; /* 116.667% */
}

.testimonials .card-div h6 {
  color: #666;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Instrument Sans' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%; /* 28.8px */
}

.testimonials .card-div .card-footer {
  display: flex;
  align-items: center;
  gap: 24px;
  align-self: stretch;

  margin-top: 20px;
}

.testimonials .card-div .card-footer .card-foo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
}

.testimonials .card-div .card-footer h4 {
  color: #1f1f1f;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Bricolage Grotesque' !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 111.111%; /* 111.111% */
}
.testimonials .card-div .card-footer p {
  color: #666;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Bricolage Grotesque' !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 111.111%; /* 111.111% */
}

/* Meet Creator */
.meet-creator {
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #1f1f1f;
}
.meet-creator .creator-div {
  border-radius: 24px;
  background: #232323;

  padding: 48px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  background-image: url(../../assets/purple/meet-creator-bg.png);
}

.meet-creator .creator-div .head-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;

  margin-bottom: 16px;
}
.meet-creator .creator-div .head-div h5 {
  color: #c892ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
}
.meet-creator .creator-div .head-div h1 {
  color: #fff;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 61.6px */
  padding: 0 !important;
  backdrop-filter: none;
  border-bottom: none;
}

.meet-creator .creator-div .carousel {
  margin-top: 56px;
}

.meet-creator .creator-div h6 {
  color: #fff;
  font-family: 'Space Grotesk' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 180%; /* 36px */
  opacity: 0.7;
}

.meet-creator .carousel-cell {
  width: 400px;
  /* height: 200px; */
  margin-right: 32px;
}

/* cell number */
.meet-creator .carousel-cell:before {
  display: block;
}

.meet-footer {
  /* display: flex; */
  margin-bottom: 48px;
  /* align-items: flex-end; */
  gap: 80px;
  /* align-self: stretch; */

  margin-top: 80px;
}

.meet-creator .get-btn {
  border-radius: 400px;
  border: 2px solid rgba(255, 255, 255, 0.24);
  background: #9831ff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

  padding: 16px 32px;
  gap: 12px;

  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 140% */

  width: -webkit-max-content;
}

.meet-footer .get-btn span {
  color: rgba(255, 255, 255, 0.6);
  font-style: normal;
  font-weight: 600;
  text-decoration-line: line-through;
}

.meet-footer p {
  opacity: 0.7;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px; /* 140% */
}
.meet-footer h4 {
  color: #fff;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.64px;
}

.meet-footer .foot-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  flex: 1 0 0;
}

.meet-creator .foo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  align-self: stretch;

  border-radius: 24px;
}

.meet-creator .foo h5 {
  color: #c892ff;
  font-family: 'Instrument Sans' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  text-transform: uppercase;
}

.meet-creator .foo .foo-div {
  display: flex;
  align-items: center;
  gap: 80px;
  align-self: stretch;
}

/* Hurry */
.hurry {
  background: #1c1c35;
  padding-top: 100px;
  padding-bottom: 100px;
}

.hurry .head-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
}

.hurry .head-div h5 {
  color: #c892ff;
  font-family: 'Instrument Sans' !important;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  text-transform: uppercase;
}
.hurry .head-div h1 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-top: 24px;
  padding: 0;
  border-bottom: 0;
  backdrop-filter: none;
}

.hurry .head-div .get-btn {
  display: flex;
  padding: 16px 32px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  align-self: stretch;
  border-radius: 400px;
  border: 2px solid rgba(255, 255, 255, 0.24);
  background: #9831ff;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  height: min-content;

  margin-top: auto;
}

.hurry .head-div .get-btn span {
  color: rgba(255, 255, 255, 0.6);
  text-decoration-line: line-through;
}

.hurry .counter {
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  margin-top: 56px;
}

.hurry .counter .time {
  display: flex;
  padding: 40px 24px;
  justify-content: center;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  border-radius: 16px;
  border: 1px solid #323232;
  background: rgba(255, 255, 255, 0.04);
}

.hurry .counter .time h4 {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.hurry .counter .time h4 span {
  opacity: 0.6;
}

.faq {
  padding-top: 100px;
  padding-bottom: 100px;
  background: #0b0b2f;
  /* background-image: url(../../assets/purple/faq-bg.png); */
  /* background-size: cover;
    background-repeat: no-repeat; */
}

.faq h5 {
  color: #c892ff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%; /* 28px */
  font-family: 'Instrument Sans' !important;
}
.faq h1 {
  color: #fff;
  font-size: 44px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  padding: 0;
  border-bottom: none;
  backdrop-filter: none;
}

.faq .text-div {
  gap: 20px;
  margin-top: 0;
}

.faq .text-div .collapse-div {
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.04);
  backdrop-filter: blur(16px);

  padding: 20px;
}

.faq .text-div .collapsible {
  display: contents;
  justify-content: space-between;
  display: flex;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 28px */
}
.faq .text-div .content {
  color: #fff;
  opacity: 0.7;

  margin-top: 12px;
}

.footer {
  padding-top: 48px;
  padding-bottom: 48px;
  background: #1f1f1f;
}

.footer .head-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  align-self: stretch;
}

.footer .head-div h1 {
  color: #fff;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer .head-div .socials {
  display: flex;
  align-items: center;
  gap: 32px;
}

.footer .head-div .socials a {
  opacity: 0.6;
  color: #fff;
  font-family: 'Instrument Sans' !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer .foot-div {
  margin-top: 64px;
  padding-top: 40px;
  border-top: 1px solid #323232;

  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

.footer .foot-div .tab-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.footer .foot-div .tab-links a {
  color: #fff;
  font-family: 'Instrument Sans' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.footer .foot-div p {
  color: #fff;
  font-family: 'Instrument Sans' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.meet-mob-footer {
  display: none;
}

.mobile-div {
  display: none;
}

.meet-creator .foo {
  margin-top: 32px;
}
/* font-family: 'Instrument Sans'; */
/* font-family: 'Bricolage Grotesque'; */
@media (min-width: 768px) and (max-width: 1056px) {
  .faq .collapsible img {
    height: -webkit-fit-content;
    margin: auto;
  }

  .workshop .cards {
    margin-left: 0;
    margin-top: 40px;
  }
  .home {
    background-size: cover;
  }
  .home .head-div {
    width: auto;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .meet-creator .foo-div {
    width: 100%;
    height: 100px;
    position: relative;
    animation-timing-function: linear;
    animation: mymove infinite;
    animation-duration: 15s;
    animation-direction: alternate;
    padding: 12px;
  }

  @keyframes mymove {
    0% {
      left: 0px;
    }
    100% {
      left: -750px;
    }
  }
  .hurry .head-div {
    display: block;
  }
  .hurry .head-div h1 {
    margin-bottom: 32px;
  }
}

@media (min-width: 576px) and (max-width: 768px) {
  .max-width {
    max-width: 95%;
  }
  .home-nav .nav-inline {
    justify-content: space-between;
  }
  .home-nav .countdowns {
  }
  .home-nav .countdowns {
    /* background: #262626; */
    display: flex;
    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }
  .home-nav {
    padding-bottom: 0;
  }

  .faq .collapsible img {
    height: -webkit-fit-content;
    margin: auto;
  }

  .workshop .cards {
    margin-left: 0;
    margin-top: 40px;
  }
  .home {
    background-size: cover;
  }
  .home .head-div {
    width: auto;
  }
  .meet-creator .foo {
    margin-top: 32px;
  }
}

.desktop {
  display: block;
}

.mobile {
  display: none;
}

.meet-creator .desktop {
  display: none;
}

@media (max-width: 576px) {
  .row {
    margin-left: env();
    margin-right: env();
    padding-left: env();
    padding-right: env();
  }
  .row > * {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: initial;
    padding-left: initial;
    margin-top: var(--bs-gutter-y);
  }
  .max-width {
    max-width: 393px;
  }

  .desktop {
    display: none;
  }

  .mobile {
    display: block;
  }

  .mobile-break {
    display: block;
  }
  .mt-mob-32 {
    margin-top: 32px;
  }

  .home-nav {
    padding-bottom: 0;
  }
  .home-nav .nav-inline {
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .home-nav h2 {
    color: #f2f2f2;
    text-align: center;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .home-nav .register-btn {
    display: flex;
    padding: 12px 32px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    border-radius: 400px;
    border: 2px solid rgba(255, 255, 255, 0.24);

    background: #005af1;

    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 155.556%; /* 155.556% */
  }

  .home-nav .countdowns {
    /* background: #323232; */

    padding: 12px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: stretch;
  }

  .home-nav .countdowns h5,
  .home-nav .countdowns h6 {
    font-size: 16px;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .home {
    padding-top: 80px;
    padding-bottom: 80px;
    background-size: cover;
  }

  .home .head-div {
    width: auto;
  }

  .home .head-div h5 {
    display: flex;
    height: 58.32px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
  }
  .home .head-div h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
  }

  .home .head-div h6 {
    font-size: 20px;
  }

  .home .rating-div {
    margin-top: 48px;
  }
  .home .rate-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
  }

  .home .rate-div h6 {
    font-size: 14px;
    color: #a4a4a4;
  }

  .home progress {
    height: 8px;
  }

  .home .progress-div p {
    font-size: 16px;
  }

  .home .creative-div {
    margin-top: 56px;
    width: auto;
    height: 400px;
  }
  .home .creative-div .img-div {
    gap: 18.4px;
    padding: 240px 18.4px 18.4px 18.4px;
  }
  .home .creative-div .img-div .img-text {
    gap: 6.9px;
  }
  .home .creative-div .img-div .img-text h2 {
    font-size: 18.4px;
  }

  .home .creative-div .img-div .img-text h6 {
    font-size: 11.5px;
  }

  .home .creative-div .img-div .img-foot p {
    font-size: 11.5px;
    display: flex;
    padding: 5.75px;
    align-items: center;
    gap: 6.9px;
    flex: 1 0 0;
    border-radius: 6.9px;
  }

  .home .creative-div .img-div .img-foot p img {
    width: 18.4px;
    height: 18.4px;
  }

  .learn,
  .workshop,
  .meet-creator {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .learn h1 {
    font-size: 32px;
  }

  .mt-mob-56 {
    margin-top: 56px;
  }
  .learn .pills {
    margin-top: 80px;
  }
  .learn .pills p {
    font-size: 16px;
    padding: 12px 16px;
    gap: 12px;
  }

  .workshop h5 {
    font-size: 16px;
  }
  .workshop h1 {
    font-size: 32px;
  }

  .workshop .text-div p {
    font-size: 16px;
  }
  .workshop .get-btn {
    font-size: 18px;
    line-height: 155.556%;
    padding: 12px 16px;
    gap: 12px;
    margin-bottom: 60px;
  }

  .workshop .card-div {
    width: 90%;
    margin: auto;
    padding: 20.444px;
  }

  @keyframes example {
    from {
      top: 30px;
    }
    to {
      top: -400px;
    }
  }
  .workshop .cards {
    height: 362px;
  }
  .workshop .card-div h3 {
    color: #1f1f1f;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Instrument Sans' !important;
    font-size: 15.333px;
    font-style: normal;
    font-weight: 700;
    line-height: 116.667%; /* 116.667% */
  }
  .workshop .card-div h6 {
    color: #666;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Instrument Sans' !important;
    font-size: 11.5px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%; /* 18.4px */
    margin-top: 10.22px;
  }
  .workshop .card-div .card-footer {
    margin-top: 20.44px;
    display: flex;
    align-items: center;
    gap: 15.333px;
    align-self: stretch;
  }
  .workshop .card-div .card-footer img {
    width: 45px;
    height: 45px;
  }
  .workshop .card-div .card-footer .card-foo {
    gap: 5.111px;
  }
  .workshop .card-div .card-footer h4 {
    font-size: 11.5px;
  }
  .workshop .card-div .card-footer p {
    font-size: 11.5px;
  }
  .meet-creator .creator-div {
    padding: 24px 16px;
    overflow: hidden;
    background-size: cover;
  }
  .meet-creator .creator-div .head-div {
    max-width: 400px;
  }
  .meet-creator .creator-div .head-div h5 {
    font-size: 16px;
  }
  .meet-creator .creator-div .head-div h1 {
    font-size: 32px;
  }
  .meet-creator .creator-div h6 {
    max-width: 365px;
  }
  .meet-creator .carousel-cell {
    width: fit-content;
    margin-right: 16px;
  }
  .meet-creator .carousel-cell img {
    width: 273.357px;
    height: 205.017px;
  }
  .meet-footer {
    margin-top: 56px;
    /* display: none; */
    max-width: 370px;
    margin-bottom: 40px;
  }
  .meet-mob-footer {
    display: block;
    max-width: 362px;
  }
  .meet-mob-footer .foot-div {
    width: fit-content;
  }
  .meet-footer .foot-div {
    padding: 12px;
  }

  .mobile-div {
    display: flex;
  }
  .meet-creator .get-btn {
    margin: auto;
    width: -webkit-fill-available;
  }

  .mt-mob-0 {
    margin-top: 0px;
  }

  .meet-footer p {
    font-size: 18px;
    line-height: 28px; /* 155.556% */
  }
  .meet-footer h4 {
    font-size: 24px;
    line-height: normal;
  }
  .meet-creator .foo {
    padding: 24px;
    overflow: hidden;
    margin-top: 48px;
  }

  .meet-creator .foo h5 {
    font-size: 16px;
    text-transform: uppercase;
  }
  .meet-creator .foo-div {
    width: 100%;
    height: 100px;
    position: relative;
    animation-timing-function: linear;
    animation: mymove infinite;
    animation-duration: 15s;
    animation-direction: alternate;
    padding: 12px;
  }

  @keyframes mymove {
    0% {
      left: 0px;
    }
    100% {
      left: -750px;
    }
  }

  .hurry,
  .faq {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .hurry .head-div {
    display: block;
  }
  .hurry .head-div h5 {
    font-size: 16px;
  }
  .hurry .head-div h1 {
    font-size: 32px;
    margin-top: 16px;
    margin-bottom: 32px;
  }
  .hurry .counter {
    margin-top: 32px;
  }

  .hurry .desktop {
    display: none;
  }
  .hurry .mobile {
    display: flex;
  }
  .hurry .counter .time {
    display: block;
  }
  .faq h5 {
    font-size: 16px;
  }
  .faq h1 {
    font-size: 32px;

    margin-top: 16px;
    margin-bottom: 40px;
  }
  .faq .collapsible p {
    font-size: 18px;
  }
  .faq .collapsible img {
    height: -webkit-fit-content;
    margin: auto;
  }

  .faq .content p {
    font-size: 16px;
  }
  .footer {
    padding-top: 60px;
    padding-bottom: 60px;
  }
  .footer .head-div h1 {
    color: #fff;
    font-size: 48px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Bebas Neue' !important;
  }
  .footer .head-div {
    gap: 16px;
  }
  .footer .head-div .socials a {
    font-size: 16px;
  }
  .footer .foot-div .tab-links a,
  .footer .foot-div p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.bg-F5F5DC {
  background-color: #f5f5dc;
}

.creatorCard {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  /* gap: 20px; */
  flex: 1 0 0;
  align-self: stretch;
  /* border-radius: 12px; */
  /* background: rgba(78, 64, 104, 0.12); */
}

.BricolageFont {
  font-family: 'Bricolage Grotesque';
}

.widthWeb {
  margin: 25px;
  width: -webkit-fill-available;
}

/* font-family: 'Instrument Sans'; */
/* font-family: 'Bricolage Grotesque'; */
