.creatorCard {
    display: flex;
    padding: 10px 20px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    /* gap: 20px; */
    flex: 1 0 0;
    align-self: stretch;
    /* border-radius: 12px; */
    /* background: rgba(78, 64, 104, 0.12); */
}

.socialCard1 {
    display: flex;
    padding: 12px 16px;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 12px;
    border: 1px solid rgba(242, 242, 242, 0.12);;
    /* background: rgba(78, 64, 104, 0.12); */
}

.BricolageFont {
    font-family: 'Bricolage Grotesque';
}
