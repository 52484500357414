.aboutCard {
  padding: 24px;
  /* gap: 10px; */
  /* align-self: stretch; */
  border-radius: 12px;
  border: 1px solid rgba(242, 242, 242, 0.24);
  /* background: rgba(255, 255, 255, 0.12); */
  /* opacity: 0.8; */
  font-size: 18px;
}

.RedHatFont {
  font-family: 'Red Hat Display' !important;
}
